import './App.css';
import { Route, Routes } from 'react-router-dom';
import Login from './Components/LoginPage';
import Password from './Components/Password';
import FP from './Components/FP';
import ProfileImage from './Components/ProfileImage';
import Logined from './Components/Logined';
import Home from './Components/Home';
import PreLoader from './PreLoader';
import { useEffect, useState } from 'react';
import EHome from './Echo_container/Home'
function App() {
  const [preload, setPreload] = useState(true)
  useEffect(() => {
    const fakeIt=()=>{
        setTimeout(() => {
          setPreload(false)
        }, 1500);
    }
    fakeIt()
    }, [])
    

  return (
    <div>
    {preload? <PreLoader/> : 
    <div>
    <Routes className='delay-500'>
      <Route path='/*' element={<Home/>}/>
      <Route path='/profileimage' element={<ProfileImage/>}/>
      <Route path='/sign' element={<Login/>}/>
      <Route path='/login' element={<Logined/>}/>
      <Route path='/fp' element={<FP/>}/>
      <Route path='/home' element={<EHome/>}/>
      <Route path='/password' element={<Password/>}/>
    </Routes></div>
    }
    </div>
  );
}

export default App;
