import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { client } from '../client'
import Spinner from '../Container/Spinner'
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { userQuery } from '../data';

const Upload = () => {
    const [loadmus, setLoadmus] = useState(false)
    const [music, setMusic] = useState()
    const [wrongmus, setWrongmus] = useState(false)
    const [user, setUser] = useState()
    const [title, setTitle] = useState('')
    const [search, setSearch] = useState('')
    useEffect(() => {
        const query = userQuery('itz.notecho')
        client.fetch(query).then((data)=>{
            setUser(data[0])
        })
    }, [])
    const schange=(e)=>{
        setSearch(e.target.value)
    }
    const tchange=(e)=>{
        setTitle(e.target.value)
    }
    const musicupload =(e)=>{
        const mus = e.target.files[0]
    if(mus.type === 'audio/mpeg' || mus.type === 'audio/ogg' || mus.type ==='audio/wav'){
            setLoadmus(true)
            setWrongmus(false)
            setMusic(true)
            client.assets.upload('file',mus,{contentType:mus.type,filename:mus.name})
            .then((document)=>{
                setMusic(document)
                setLoadmus(false)
            })
            .catch((error)=>{
                console.log('Upload error ',error);
            })
        }}
    const savemetadata=()=>{
        if(music?.url && search !== '' && title !==''){
            const doc={
                _type:'metadata',
                image:search,
                title:title,
                category:search,
                music:{
                    _type:'file',
                    asset:{
                        _type:'reference',
                        _ref:music?._id
                    }
                },
                postedBy:{
                    _type:'postedBy',
                    _ref:user?._id
                }
                
            }
            client.create(doc).then(()=>{
                console.log('uploaded')
            })
        }
    }
return (
    <div className='flex flex-col justify-center text-center items-center'>
        <Link to='/login'>Login</Link>
        <br />
        <div className='w-2/3 md:w-1/3 bg-gray-900 text-white rounded-lg h-[120px] pt-2 '>
                    {loadmus &&
                    <div className='flex flex-row justify-center'>
                        <Spinner/>
                    </div>
                    }
                    {wrongmus &&
                    <p className='text-red-600'>Wrong music file type</p>
                    }
                    {!music ?( 
                    <label>
                    <CloudUploadIcon className='text-red-400' />
                    <br />
                    <h1>Upload your music here</h1>
                    <span className='text-gray-400 px-3' style={{fontSize:'10px'}}>Recommended: Upload mp3, wav, m4a ,etc of high audio quality</span>
                    <input type="file" name='music' className='w-0 h-0' onChange={musicupload} />
                    </label>):(
                        <div className='text-gray-300 py-6'>
                            <h1 style={{fontSize:'12px'}}>{music?.originalFilename}</h1>
                        </div>
                    )}
                </div>
                <br />
                <input type="text" onChange={tchange} className='px-2 py-2 bg-gray-900 text-red-100 rounded-lg' placeholder='title'/>
                
                <br />
                <input type="text" onChange={schange} className='px-2 py-2 bg-gray-900 text-red-100 rounded-lg' />
                <br />
                <button onClick={savemetadata} className='px-2 py-2 bg-red-600 hover:bg-red-900 hover:text-red-100'>Submit</button>
    </div>
)
}

export default Upload