import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import {client} from '../client'
import Spinner from '../Container/Spinner';
import { Avatar } from '@mui/material';
import { deepOrange } from '@mui/material/colors';
const Music_post = ({user}) => {
    const lon=localStorage.getItem('lon')
    const nav = useNavigate()
    const [image, setImage] = useState()
    const [loadimg, setLoadimg] = useState(false)
    const [loadmus, setLoadmus] = useState(false)
    const [music, setMusic] = useState()
    const [fields, setFields] = useState()
    const [caption, setCaption] = useState('')
    const [wrongimg, setWrongimg] = useState(false)
    const [wrongmus, setWrongmus] = useState(false)
    const [des, setDes] = useState('')
    const captionIt=(e)=>{
        const cap = e.target.value
        setCaption(cap)
    }
    const desIt=(e)=>{
        const cap = e.target.value
        setDes(cap)
    }
    const musicupload =(e)=>{
        const mus = e.target.files[0]
    if(mus.type === 'audio/mpeg' || mus.type === 'audio/ogg' ){
            setLoadmus(true)
            setWrongmus(false)
            setMusic(true)
            client.assets.upload('file',mus,{contentType:mus.type,filename:mus.name})
            .then((document)=>{
                setMusic(document)
                setLoadmus(false)
            })
            .catch((error)=>{
                console.log('Upload error ',error);
            })
    }
else{
    setLoadmus(false)
    setWrongmus(true)
}
}
const imgupload =(e)=>{
    const img = e.target.files[0]
if(img.type === 'image/jpeg' || img.type === 'image/jpg' || img.type === 'image/png' || img.type === 'image/ico'){
        setLoadimg(true)
        setWrongimg(false)
        setImage(true)
        client.assets.upload('image',img,{contentType:img.type,filename:img.name})
        .then((document)=>{
            setImage(document)
            setLoadimg(false)
        })
        .catch((error)=>{
            console.log('Upload error ',error);
        })
}
else{
setLoadimg(false)
setWrongimg(true)
}
}

const saveMusic=()=>{
    if (image?.url && music?.url && caption !== '' && des!== '' &&caption.length !== 0 && des.length !== 0 && user ){
        setFields(true)
        const doc={
            _type:'music',
            title:caption,
            category:des,
            image:{
                _type:'image',
                asset:{
                    _type:'reference',
                    _ref:image?._id
                }},
            music:{
                _type:'file',
                asset:{
                    _type:'reference',
                    _ref:music?._id
                }
            },
            postedBy:{
                _type:'postedBy',
                _ref:user?._id
            }
            
        }
        client.create(doc).then(()=>{
            nav('/',{replace:true})
        })
    }
    else{
        setFields(false)
    }
}
return (
    <div className='h-full'>
        {user && lon=='yes'&&
            <div className='flex flex-col text-center items-center justify-center'>
                <br />
                <h1 className='text-gray-400 font-bold' style={{fontSize:'30px'}}>Music</h1>
                <br />
                {fields === false &&(
                    <p className='text-cyan-600'>Please fill all the fields to make an ink wave</p>
                )

                }
                <div className='w-2/3 md:w-1/3 bg-gray-900 text-white rounded-lg h-[120px] pt-2 '>
                    {loadmus &&
                    <div className='flex flex-row justify-center'>
                        <Spinner/>
                    </div>
                    }
                    {wrongmus &&
                    <p className='text-cyan-600'>Wrong music file type</p>
                    }
                    {!music ?( 
                    <label>
                    <CloudUploadIcon className='text-cyan-400' />
                    <br />
                    <h1>Upload your music here</h1>
                    <span className='text-gray-400 px-3' style={{fontSize:'10px'}}>Recommended: Upload mp3, wav, m4a ,etc of high audio quality</span>
                    <input type="file" name='music' className='w-0 h-0' onChange={musicupload} />
                    </label>):(
                        <div className='text-gray-300 py-6'>
                            <h1 style={{fontSize:'12px'}}>{music?.originalFilename}</h1>
                            <h1 style={{fontSize:'10px'}} className='text-gray-400'>{user?.slug}</h1>
                        </div>
                    )}
                </div>
                <br />
                {music=== undefined &&
                    <Link className='bg-cyan-600 text-cyan-50 rounded-lg px-2 py-2 hover:bg-gray-800 hover:text-cyan-600' to='/lyrics'>Make Music Lyrics</Link>
                }
                <br />
                <div className='w-2/3 md:w-1/3 bg-gray-900 text-white rounded-lg h-320  '>
                    {loadimg &&
                    <div className='flex flex-row justify-center'>
                        <Spinner/>
                    </div>
                    }
                    {wrongimg &&
                    <p>Wrong image type</p>
                    }
                    {!image ?(
                    <label>
                    <CloudUploadIcon className='text-cyan-400' />
                    <br />
                    <h1>Upload cover image here</h1>
                    <span className='text-gray-400 px-3' style={{fontSize:'10px'}}>Recommended: Upload png, jpeg, jpg ,etc of high  quality</span>
                    <input type="file" name='music' className='w-0 h-0' onChange={imgupload}/>
                    </label>):(
                        <div className='relative h-full'>
                            <img src={image.url} alt="img upload" className='h-full w-full rounded-lg' />
                        </div>
                    )}
                </div>
                <div className='item-center flex text-center justify-center pt-6 w-full'>
                    <input type="text" onChange={captionIt} className='bg-inherit w-2/3  md:w-1/3 border-2 border-cyan-400 px-2 py-4 rounded-xl text-cyan-100 hover:border-gray-700' placeholder='Caption' />
                </div>
                <br />
                Posted By
                <div className=' flex justify-center w-full'>
                    <div className='p-1 flex rounded-lg text-white bg-gray-900 w-2/3 md:w-1/3 h-[60px]'>
                    {user && user?.imgl.length > 1 &&
                            <Avatar src={user?.imgl} fontSize='large'/>
                            }
                            {user && user?.imgl.length == 1 &&
                            <Avatar sx={{bgcolor: deepOrange[400]}} fontSize='20px'>{user?.imgl}</Avatar>
                            }
                            <div className="flex flex-col px-2">
                            <h1 className='text-cyan-600'>{user?.userName}</h1>
                            <span className='text-gray-500' style={{fontSize:'12px'}}>{user?.slug}</span>
                        </div>
                    </div>
                </div>
                <br />
                <div children="flex justify-center items-center w-2/3 md:bg-w1/3">
                    <h1 className='text-gray-400'>Add genre and description</h1>
                    <br />
                    <textarea rows={6} cols={35} onChange={desIt} type="text" placeholder='Genre' className='px-2 py-4 rounded-lg bg-inherit border-2 border-cyan-600 hover:border-cyan-400'/>
                </div>
                <br />
                <div className='flex justify-center items-center w-full'>
                    <button className='bg-cyan-600 text-cyan-50 rounded-lg px-2 py-2 hover:bg-gray-800 hover:text-cyan-600' onClick={saveMusic}>Post</button>
                </div>
                <br />
                <br />
                </div>
        }
        {(lon=='no' || lon==null) &&
                <div className='pt-3'>
                            <Link className='bg-cyan-600 text-cyan-50 rounded-lg px-2 py-2 hover:bg-gray-800 hover:text-cyan-600' to='/login'>Login</Link>
                        </div>
                    }
                    {user==undefined && lon=='yes' &&
                    <div className='flex flex-row justify-center'>
                        <Spinner/>
                    </div>
                    }
    </div>
)
}

export default Music_post
