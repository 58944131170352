import React from 'react'
import CloseIcon from '@mui/icons-material/Close';
import '../App.css'
function Popup(props) {
return (props.trigger) ? (
    <div className='popup '>
        <div className='popup-inner rounded-lg'>
            <button className='btn-close' onClick={()=>props.setTrigger(false)}><CloseIcon/></button>
            { props.children }
        </div>
    </div>
):""
}

export default Popup
