import React from 'react'
import img from '../Assets/sound-wave.png'
import {Link} from 'react-router-dom'
import MusicNoteIcon from '@mui/icons-material/MusicNote';
import Avatar from '@mui/material/Avatar';
import Spinner from './Spinner'
import { deepOrange } from '@mui/material/colors'
const SideBar = ({user}) => {
const lon =localStorage.getItem('lon')
return (
    <div className='text-white'>
        <div className="flex flex-col">
        <Link to='/'>
            <div className='p-3 flex'>
                <img src={img} alt="logo" height='85px' width='85px' />
                <span className={`pt-4 px-4 font-extrabold bg-clip-text text-transparent bg-gradient-to-r from-sky-400 to-cyan-400`} style={{fontSize:'30px'}}>Echo</span>
            </div>
        </Link>
        <div className={`backdrop-blur-[30px] h-[70vh] w-full bg-slate-950 rounded-lg `}>
            <div className='flex flex-col p-2 gap-4 pt-4 py-4 divide-y divide-gray-800'>
                <div>
                {user && lon=='yes' &&
                <Link to={`/userprofile/${user?.slug}`}>
                <div className='flex'>
                {user && user?.imgl.length > 1 &&
                            <Avatar src={user?.imgl}/>
                            }
                            {user && user?.imgl.length == 1 &&
                            <Avatar sx={{bgcolor: deepOrange[400]}}>{user?.imgl}</Avatar>
                            }
                <span className='pt-2 px-2 font-extrabold text-gray-400' style={{fontSize:'13px'}}>{user?.slug}</span>
                </div>
                </Link>
                }
                {user==undefined && lon=='yes' &&
                    <div className=' flex flex-row justify-center'>
                        <Spinner/>
                    </div>
                }
                {(lon=='no' || lon==null) &&
                    <div className='flex justify-between'>
                        <div className='px-4 py-2'>
                            <Link className='bg-cyan-900 text-sky-300 rounded-lg px-2 py-2 hover:bg-sky-600 hover:text-cyan-400' to='/sign'>Sign In</Link>
                        </div>
                        <div className='px-2  py-2'>
                            <Link className='bg-cyan-900 text-sky-300 rounded-lg px-2 py-2 hover:bg-sky-600 hover:text-cyan-400' to='/login'>Login</Link>
                        </div>
                    </div>
                }
                </div>
        
                {user &&
                <div className='pt-2 flex flex-col gap-2 px-2'>
                    <div className=' px-1 py-1 rounded-lg'>
                        <Link className='pt-1 text-sky-400 font-bold hover:text-cyan-600' to={'/my/albums'}><MusicNoteIcon fontSize='small'/> <span className='text-md px-1 py-1  pt-1'>My albums</span></Link>
                    </div>
                </div>
                }
            </div>
            <div>
                <br />
            </div>
        </div>
        </div>
    </div>
)
}

export default SideBar