import React, { useEffect } from 'react'
import './App.css'
import logo from './Assets/sound-wave.png'
import Lottie from 'react-lottie-player'
import letter from './Assets/preload.json'
import WebFont from 'webfontloader';

const PreLoader = () => {
    useEffect(() => {
        WebFont.load({
        google: {
            families: ['Croissant One', 'Fuggles','Mooli','Rajdhani',]
        }
        });
    }, []);
return (
    <div className='text-center pt-4 flex flex-col items-center justify-center h-screen  '>
            <div className='h-2/4 md:h-2/3 transition-shadow duration-200 shadow-2xl shadow-sky-950 border-spacing-24  items-center text-center flex flex-col justify-center rounded-full px-10 md:px-16    border-cyan-500 '>
            <Lottie
            loop
            animationData={letter}
            play
            style={{ width: 250, height: 250 }}
            />
            <div className='flex gap-3 text-white/100'>
                <img className='h-[42px] w-[42px]' src={logo} alt="" />
                <h1 className='text-3xl font-extrabold '> . </h1>
                <h1 className='font4 font-extrabold text-3xl pt-2'>Echo</h1>
            </div>
            </div>
    </div>
)
}

export default PreLoader
