import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { client } from '../client'
import { epin } from '../data'
import Avatar from '@mui/material/Avatar';
import { cyan } from '@mui/material/colors'
import Spinner from '../Container/Spinner';

const Epin = () => {
const {id}=useParams()
const [pin, setPin] = useState()
useEffect(() => {
const q=epin(id)
client.fetch(q).then((data)=>{
    setPin(data[0])
})
}, [])
return (
    <div >
        <br />
        {pin == undefined &&
        <div className='text-center items-center flex flex-col justify-center'>
            <Spinner/>
        </div>
        }
        {pin &&
        <div>
        <div className='flex flex-row justify-center'>
        <div>
        {pin?.postedBy?.imgl.length > 1 &&
        <Avatar src={pin?.postedBy.imgl}/>
        }
            {pin?.postedBy.imgl.length == 1 &&
            <Avatar sx={{bgcolor: cyan[400]}}>{pin?.postedBy.imgl}</Avatar>
            }
        </div>
        <Link to={`/userprofile/${pin?.postedBy.slug}`} className='p-2'>
            <span className='text-gray-400'>{pin?.postedBy.slug}</span>
        </Link>
        </div>
        <div className='text-center pt-4 px-4 py-2 rounded-lg'>
            <span className='font-extrabold'>{pin?.text}</span>
            <div className='pt-2'>
          <span className='text-xs px-5'>Posted by <b className='text-sky-400'>{pin?.postedBy.userName}</b></span>
          </div>
        </div>
        <div className='lex flex-col text-center pt-4'>
            <span className='text-cyan-400'>Refered in the post :-</span>
            <div className='pt-1 '>
            {pin?.refpro.map((ref)=>(
                <div className='py-1 '>
                <Link to={`/userprofile/${ref}`} className='bg-black text-sky-400 px-2 py-1 rounded-lg hover:text-lime-400'>@{ref} <br /></Link></div>
            ))}
            </div>
        </div>
        </div>
        }
    </div>
)
}

export default Epin