import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';
import VerifiedIcon from '@mui/icons-material/Verified';
import Spinner from './Spinner'
const LyricsPg = () => {
let sn=useParams()
const [art, setArt] = useState()
function LyricsText(prop){
    let text=prop.text
    text=text.split('Lyrics')[1]
    return text.split('\n').map(str => <p>{str}</p>);
}
async function getartist(url){
    const res = await fetch(url)
    var data = await res.json()
    setArt(data)
}
const [ld, setLd] = useState()
async function getlyr(url){
    const res = await fetch(url)
    var data = await res.json()
    setLd(data)
  }
getlyr(`https://lyrics-zizg.onrender.com/${sn.sn}`)
if(ld?.artist !== undefined){
    getartist(`https://lyrics-zizg.onrender.com/artist/${ld.artist}`)
}

return (
    <div className='flex flex-col'>
        <br />
        {ld == undefined &&
        <div className='flex flex-col text-center items-center justify-center'>
            <Spinner/>
        </div>
        }
        {ld !== undefined &&
        <div className='flex flex-col md:flex-row  text-center  gap-4'>
            <div className='w-full px-10 md:w-1/2 flex flex-col gap-3 justify-center items-center text-center '>
                <div className='bg-black rounded-full px-6 py-2 '>
                <span className='text-cyan-400 font-bold text-md truncate'>{ld.Song_name}</span>
                </div>
                <div className='w-full pt-6 px-1 bg-black rounded-lg'>
                    <div className='text-sky-600'>
                    <LyricsText text={ld?.Lyrics}/>
                    </div>
                </div>
            </div>
            <div className='w-full md:w-1/2 flex flex-col text-center items-center gap-3'>
                <div className='bg-black rounded-full w-[140px] py-2'>
                    <span className='text-cyan-400 pt-2 font-bold'>Artist Info</span>
                </div>
                <div className='pt-4 flex flex-col gap-2'>
                    <div className='flex flex-row gap-2'>
                        <span className='text-gray-400 font-extrabold'>Artist Name : </span>
                        <span className='text-cyan-400'>{art?.name}</span>
                    </div>
                    <div className='flex flex-row gap-2'>
                        {art?.is_verified ==true &&
                            <span className='text-gray-400 font-extrabold'>Verified: </span>
                        }
                    </div>
                </div>
            </div>
        </div>
        }
        <span className='font-bold text-cyan-400'></span>
    </div>
)
}

export default LyricsPg