import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Spinner from './Spinner'
import CreateIcon from '@mui/icons-material/Create';
import Box from '@mui/material/Box';
import SendIcon from '@mui/icons-material/Send';
import { cyan } from '@mui/material/colors'
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { idcheck } from '../data';
import { client } from '../client';
const Post = ({user}) => {
  const [tag, settag] = useState('')
const lon = localStorage.getItem('lon')
const [prolist, setProlist] = useState([])
const [open, setOpen] = React.useState(false);
const handleOpen = () => setOpen(true);
const handleClose = () => setOpen(false);
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  color:'cyan',
  transform: 'translate(-50%, -50%)',
  width: 350,
  bgcolor:'#111111',
  border: '5px solid #111111',
  boxShadow: 34,
  p: 4,
};
var l=prolist
const nav = useNavigate()
const cfref_id=(e)=>{
  settag(e.target.value)
  let words=tag.split(" ")
  let i=words.length
  const last=words[words.length-1]
  let profiles=last.split('@')
  const id=idcheck(profiles[1])
  client.fetch(id).then((data)=>{
  if(data[0] !== undefined){
  setProlist(prolist.concat(data[0].slug))
  }
})
}
l=[...new Set(l)]
const send=()=>{
  const doc={
    _type:'epin',
    text: tag,
    refpro:l,
    postedBy:{
      _type:'postedBy',
      _ref:user?._id
  }
  }
  client.create(doc).then(()=>{
    nav('/',{replace:true})
  })
}
const visible_pro=l.map((profields)=><span> @{profields} </span>)

return (
    <div>
      <div className='text-center flex flex-col justify-between'>
      <h1 className='text-gray-400' style={{fontSize:'32px'}}>Post</h1>
      {(lon==null || lon == "no") &&
      <div className='text-center'>
        <h1>Please Login or Sign up first to post </h1>
        <br />
        <div className='flex  justify-center'>
          <div className='px-2'>
          <Link className='bg-red-600 text-red-50 rounded-lg px-2 py-2 hover:bg-gray-800 hover:text-red-600' to='/login'>Login</Link></div>
          <div className='px-2'>
          <Link className='bg-red-600 text-red-50 rounded-lg px-2 py-2 hover:bg-gray-800 hover:text-red-600' to='/login'>Sign In</Link>
          </div>
        </div>
      </div>
      }
      {user == undefined && lon=='yes' &&
        <div className='flex flex-row justify-center'>
        <Spinner/>
        </div>
      }
      {user !== undefined && lon=='yes' &&
      <div className='px-5' >
        <div className='flex flex-row justify-between md:px-24 px-8'>
          <div  className='rounded-full px-2 py-2 bg-black text-cyan-400 hover:bg-sky-900 hover:text-cyan-400'>
            <Link onClick={handleOpen}><CreateIcon fontSize='large'/></Link>
        <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className='rounded-lg'>
        <Typography className='text-center' id='modal-modal-title'>
          <span className='text-sky-400 text-xl font-extrabold'>Echo Pins</span>
        </Typography>
        <div id='modal-modal-description' className='py-2 flex flex-col'>
          <span className='text-gray-400'>Jot down your text below</span>
          <span className='text-sm text-gray-500'>Use '@' to tag someone</span>
          <div className='py-2 flex flex-col justify-between'>
            <Link to={`/userprofile/${user?.slug}`} className='py-2 flex flex-row hover:font-extrabold '>
            <div>
            {user && user?.imgl.length > 1 &&
            <Avatar src={user?.imgl}/>
            }
            {user && user?.imgl.length == 1 &&
            <Avatar sx={{bgcolor: cyan[400]}}>{user?.imgl}</Avatar>
            }
            </div>
            <span className='p-2 text-sky-400'>{user?.slug}</span>
            </Link>
            <div className='items-center '>
              <textarea placeholder='Input your text for pin' onChange={cfref_id} cols="30" rows="2" className=' px-2 py-2 rounded-lg bg-slate-900 text-gray-300'></textarea>
            </div>
            <div className='items-center text-center py-1'>
              <button onClick={send} className='px-6 py-1 border-2 border-cyan-900 rounded-md hover:bg-sky-400 hover:text-cyan-900 hover:border-sky-400'><SendIcon fontSize='large'/></button>
            </div>
            <div className=''>
              <span className='text-gray-400'>References:</span>
              <span>{visible_pro}</span>
            </div>
          </div>
        </div>
        </Box>
      </Modal>
          </div>
          <div  className='rounded-full px-2 py-2 bg-black text-cyan-400 hover:bg-sky-900 hover:text-cyan-400'>
            <Link><CreateIcon fontSize='large'/></Link>
          </div>
          <div  className='rounded-full px-2 py-2 bg-black text-cyan-400 hover:bg-sky-900 hover:text-cyan-400'>
            <Link><CreateIcon fontSize='large'/></Link>
          </div>
        </div>
        <br />
        <div className='flex flex-col text-center items-center md:flex-row justify-between md:px-24 px-8 '>
          <div className='px-2 py-2'>
            <div className='px-2 py-1 hover:px-1.5 hover:py-1.5 border-2 border-white rounded-lg'>
            <Link className='flex flex-col' to='/mpost'>
            <div className='h-[260px] w-[200px] text-center items-center  border-1  bg-slate-950 hover:bg-slate-800 rounded-lg border-1 border-slate-800'>
              <div className='hover:px-1 hover:py-1'>
              <img className='rounded-lg w-full h-[120px]' src="https://images.unsplash.com/photo-1494232410401-ad00d5433cfa?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1470&q=80" alt="" />
              </div>
              <br />
              <h1 className='text-gray-400'>Post Music</h1>
              <span className='text-xs px-0.5'>Post music and remixes which could add into echo raw music lib.</span>

            </div>
            </Link>
            </div>
          </div>
          
          <div className='px-2 py-2'>
            <div className='px-2 py-1 hover:px-1.5 hover:py-1.5 border-2 border-white rounded-lg'>
            <Link className='flex flex-col' to='/lyrics'>
            <div className='h-[260px] w-[200px] text-center items-center  border-1  bg-slate-950 hover:bg-slate-800 rounded-lg border-1 border-slate-800'>
              <div className='hover:px-1 hover:py-1'>
              <img className='rounded-lg w-full h-[120px]' src="https://images.unsplash.com/photo-1442323794357-25b2ec110967?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=870&q=80" alt="" />
              </div>
              <br />
              <h1 className='text-gray-400'>Make Lyrics</h1>
              <span className='text-xs px-0.5'>Make lyrics by finding similar and ryhming words on the key note from echo music lib.</span>
            </div>
            </Link>
            </div>
          </div>
          
          <div className='px-2 py-2'>
            <div className='px-2 py-1 hover:px-1.5 hover:py-1.5 border-2 border-white rounded-lg'>
            <Link className='flex flex-col' to='/imgcard'>
            <div className='h-[260px] w-[200px] text-center items-center  border-1  bg-slate-950 hover:bg-slate-800 rounded-lg border-1 border-slate-800'>
              <div className='hover:px-1 hover:py-1'>
              <img className='rounded-lg w-full h-[120px]' src="https://images.unsplash.com/photo-1507838153414-b4b713384a76?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=870&q=80" alt="" />
              </div>
              <br />
              <h1 className='text-gray-400'>Music Card</h1>
              <span className='text-xs px-0.5'>Make perfect music cards and share it to your friends and fam ❤️.</span>
            </div>
            </Link>
            </div>
          </div>
          
          <div className='px-2 py-2'>
            <div className='px-2 py-1 hover:px-1.5 hover:py-1.5 border-2 border-white rounded-lg'>
            <Link className='flex flex-col' to='/lyrics'>
            <div className='h-[260px] w-[200px] text-center items-center  border-1  bg-slate-950 hover:bg-slate-800 rounded-lg border-1 border-slate-800'>
              <div className='hover:px-1 hover:py-1'>
              <img className='rounded-lg w-full h-[120px]' src="https://images.unsplash.com/photo-1478720568477-152d9b164e26?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=870&q=80" alt="" />
              </div>
              <br />
              <h1 className='text-gray-400'>Cinematic</h1>
              <span className='text-xs px-0.5'>Upload your cinematic animations or video clips that could make a person smnile 😎</span>
            </div>
            </Link>
            </div>
          </div>

          
        </div>
        
      </div>
      }
      <br />
      <br />
      <div style={{backgroundImage:`linear-gradient(to right,rgba(0,0,0,0.2),rgba(0,0,0,1)))` ,backgroundRepeat:'no-repeat',backgroundPosition:'center',backgroundSize:'cover'}} className='h-screen text-white md:hidden flex'></div>
      </div>
    </div>
  )
}

export default Post
