import React from 'react'
import {Bars} from 'react-loader-spinner'
const Spinner = () => {
return (
    <div>
        <Bars
    height="60"
    width="60"
    color="#00FFFF"
    ariaLabel="bars-loading"
    wrapperStyle={{}}
    wrapperClass=""
    visible={true}
    />
    </div>
)
}

export default Spinner