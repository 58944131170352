import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import Spinner from './Spinner'
const Chart = () => {
const {n}=useParams()
let d={}
const [currSong, setCurrSong] = useState('')
const data=(n)=>{
if (n == 'global'){
    d={title:'Global hits',ls:'https://proxy.cors.sh/https://api.musixmatch.com/ws/1.1/chart.tracks.get?chart_name=mxmweekly_new&country=xs&f_has_lyrics=1&apikey=93ae95e5d7a97ed84aa29eed8186a168'}
}
if (n == 'in'){
    d={title:'Indian hits',ls:'https://proxy.cors.sh/https://api.musixmatch.com/ws/1.1/chart.tracks.get?chart_name=mxmweekly_new&country=in&f_has_lyrics=1&apikey=93ae95e5d7a97ed84aa29eed8186a168'}
}
if (n == 'hip-hop'){
    d={title:'Hip Hop'}
}
}
const [datalog, setDatalog] = useState({})
const [res, setres] = useState('')
useEffect(() => {
    axios.get(d?.ls,
    {
        headers: {
            'x-cors-api-key': 'temp_95f1088abe978e9757b4a4bf585dd482',}
    })
    .then(res=>setDatalog(res.data?.message.body))
}, [])
data(n)
useEffect(() => {

if (currSong.length > 0){
    const options = {
        method: 'GET',
        headers: {
        'X-RapidAPI-Key': '2a3db54accmsh7a5f05d71604c1ep145093jsnef3691c3ece0',
        'X-RapidAPI-Host': 'deezerdevs-deezer.p.rapidapi.com'
        }
    };
    let song;
    fetch(`https://deezerdevs-deezer.p.rapidapi.com/search?q=${currSong}`, options)
    .then(response => response.json())
    .then(response => setres(response.data[0].preview))
    .then(song = new Audio('https://cdns-preview-7.dzcdn.net/stream/c-7753faf802559b7a144fb640c7e121c1-3.mp3'))
    .then(song.play())
    
    
}
}, [])

return (
    <div className='text-center items-center'>
        <br />
        <h1 className='text-2xl text-gray-400 font-bold'>{d?.title}</h1>
        <br />
        <span className='font-bold text-gray-400'> Today's {d?.title}</span>
        <div className='flex flex-wrap justify-center w-full gap-4'>
            <br />
            {Object.keys(datalog).length == 0 &&
                <div className='flex flex-col justify-center text-center items-center '>
                    <Spinner/>
                </div>
            }
            {datalog.track_list?.map((chart)=>(
                <div className=' rounded-lg px-4 w-full py-4 text-cyan-400 relative'>
                    <img className='rounded-lg h-[130px]  md:h-[180px] w-full object-cover ' src="https://images.unsplash.com/photo-1470813740244-df37b8c1edcb?crop=entropy&cs=tinysrgb&fit=crop&fm=jpg&h=700&ixid=MnwxfDB8MXxyYW5kb218MHx8c3BhY2V8fHx8fHwxNjkyMDM4OTk1&ixlib=rb-4.0.3&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=900" alt="" />
                    <div class="absolute bottom-0 pr-5 text-center py-16 text-center   w-full">
                    <h1 class="text-white text-opacity-50 font-bold text-3xl truncate ">{chart?.track.track_name}</h1>
                    <h1 class="text-white text-opacity-75 font-bold text-xs truncate ">{chart?.track.artist_name}</h1>
                    <button onClick={() => setCurrSong(chart?.track.track_name)}>Play</button>
                    </div>
                </div>
            ))}
        </div>
    </div>
)
}

export default Chart
