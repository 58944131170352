import React, { useState } from 'react'
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Spinner from '../Container/Spinner';
import { NavLink, useNavigate } from 'react-router-dom'
import img from '../Assets/sound-wave.png'
import { client } from '../client';
const ProfileImage = () => {
    const userInfo = localStorage.getItem('user') !== undefined ? JSON.parse(localStorage.getItem('user')) : localStorage.clear()
    const nav=useNavigate()
    const [image, setImage] = useState()
    const [loadimg, setLoadimg] = useState(false)
    const [wrongimg, setWrongimg] = useState(false)
    
    const imgupload =(e)=>{
        const img = e.target.files[0]
    if(img.type === 'image/jpeg' || img.type === 'image/jpg' || img.type === 'image/png' || img.type === 'image/ico'){
            setLoadimg(true)
            setWrongimg(false)
            setImage(true)
            client.assets.upload('image',img,{contentType:img.type,filename:img.name})
            .then((document)=>{
                setImage(document)
                setLoadimg(false)
            })
            .catch((error)=>{
                console.log('Upload error ',error);
            })
    }
    else{
    setLoadimg(false)
    setWrongimg(true)
    }
    }
    const setpf=()=>{
        if(image.url){
            client.patch(`${userInfo?._id}`).set({imgl:`${image.url}`}).commit();
            nav('/',{replace:true})
        }
    }
    return (
    <div  className='h-screen text-white bg-gradient-to-tr from-black to-slate-900'>
    <header className="text-gray-400 body-font">
<div className="container mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center">
    <nav className="flex lg:w-2/5 flex-wrap items-center text-base md:ml-auto">
    <NavLink className="mr-5 hover:text-white" to='/login'>Login</NavLink>
    <NavLink className="mr-5 hover:text-white" to='/sign'>Sign</NavLink>
    </nav>
    <NavLink className="flex order-first lg:order-none lg:w-1/5 title-font font-medium items-center text-white lg:items-center lg:justify-center mb-4 md:mb-0">
    <img src={img} alt="logo"  height='80px' width='80px'/>
    <span className="ml-3 text-xl xl:block font-extrabold lg:hidden bg-clip-text text-transparent bg-gradient-to-br from-red-600 to-red-800" style={{fontSize:'35px'}}>Echo</span>
    </NavLink>
    <div className="lg:w-2/5 inline-flex lg:justify-end ml-5 lg:ml-0">
    </div>
</div>
</header>
    <br />
    <br />
    <div className='flex flex-col justify-center items-center text-center'>
        <span className='font-extrabold text-red-600'>Upload Profile image</span>
        <br />
        <div className='w-2/3 md:w-1/3 bg-gray-900 text-white rounded-lg h-320  '>
                    {loadimg &&
                    <div className='flex flex-row justify-center'>
                        <Spinner/>
                    </div>
                    }
                    {wrongimg &&
                    <p>Wrong image type</p>
                    }
                    {!image ?(
                    <label>
                    <CloudUploadIcon className='text-red-400' />
                    <br />
                    <h1>Upload cover image here</h1>
                    <span className='text-gray-400 px-3' style={{fontSize:'10px'}}>Recommended: Upload png, jpeg, jpg ,etc of high  quality</span>
                    <input type="file" name='music' className='w-0 h-0' onChange={imgupload}/>
                    </label>):(
                        <div className='relative h-full'>
                            <img src={image.url} alt="img upload" className='h-full w-full rounded-lg' />
                        </div>
                    )}
                </div>
                <br />
        <div className='flex flex-col '>
            <button className='px-2 py-2 bg-red-600 hover:bg-red-400 rounded-lg' onClick={setpf}>Set Profile</button>
            <br />
            <button className='px-2 py-2 bg-red-600 hover:bg-red-400 rounded-lg ' onClick={()=>{nav('/',{replace:true})}}>Skip</button>
        </div>
    </div>
    </div>
)
}

export default ProfileImage