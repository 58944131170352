export const userQuery=(user) =>{
    const query = `*[_type=='user' && _id == '${user}']`
    return query
}
export const emailcheck=(email) =>{
    const query = `*[_type=='user' && email == '${email}']`
    return query
}
export const login=(user)=>{
    const query = `*[_type=='user' && email == '${user}']`
    return query
}
export const idcheck=(id)=>{
    const query=`*[_type=='user' && slug== '${id}']{slug , userName}`
    return query
}
export const post=()=>{
    const query=`*[_type=='epin'] | order(_createdAt desc){postedBy->{
        slug,
        imgl,
    userName},text,_id}`
    return query
}
export const epin=(id)=>{
    const query=`*[_type=='epin' &&  _id== '${id}']{postedBy->{
        slug,
        imgl,userName},text,_id,refpro}`
    return query
}
export const userpf=(id)=>{
    const query=`*[_type=='user' && slug== '${id}']{slug , userName, imgl, email}`
    return query
}