import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { client } from '../client'
import { userpf } from '../data'
import Spinner from './Spinner'
import { Avatar } from '@mui/material'

const UserPf = () => {
const {id} = useParams()
const [user, setuserpf] = useState()
useEffect(() => {
    const q= userpf(id)
    client.fetch(q).then((data)=>{
        setuserpf(data[0])
    })
}, [])

return (
    <div>
        {user == undefined &&
        <div className='flex flex-col justify-center items-center text-center'>
            <Spinner/>
        </div>
        }
        {user &&
        <div className='flex flex-col '>
            <br />
        <div className="flex flex-col justify-center items-center">
        <img src="https://source.unsplash.com/1600x900/?temples,nature,technology" className="rounded-lg w-full h-[170px] md:h-[220px] shadow-lg object-cover" alt="" />
        {user.imgl.length > 1 &&
        <img
            className="rounded-full w-20 h-20 -mt-10 shadow-xl object-cover"
            src={user?.imgl}
            alt="user-pic"
            />
        }
        {user.imgl.length == 1 &&
            <div className='rounded-full bg-black text-sky-400 px-1 py-4 h-[62px] -mt-10 shadow-xl object-cover text-cnter items-center'>
            <span className="text-2xl px-4 py-4 font-bold">
                {user.imgl}
            </span>
            </div>
        }
        <span className='font-bold text-2xl text-sky-400'>{user.userName}</span>
        <span className='font-bold text-sm text-gray-400'>@{user.slug}</span>
        </div>
        <div className='flex flex-row justify-start p-2'>
            <br />
            <span className='px-2 font-bold'>Contact info : </span>
            <span className='px-2 text-cyan-400 text-md font-bold'> {user.email} </span>
        </div>
        
        </div>
        
        }
    </div>
)
}

export default UserPf