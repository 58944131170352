import React, { useEffect, useState } from 'react'
import { userQuery } from '../data'
import { client } from '../client'
import Sidebar from '../Echo_components/Sidebar'
import '../App.css'
import { Link } from 'react-router-dom'
import { Avatar } from '@mui/material'
import { deepOrange } from '@mui/material/colors'
import Spinner from '../Container/Spinner'
import stamp from '../Assets/stamp.png'
const EHome = () => {
    const userInfo = localStorage.getItem('user') !== "undefined" ? JSON.parse(localStorage.getItem('user')) : localStorage.clear()
    const lon=localStorage.getItem('lon')
    
    const [user, setUser] = useState()
    
    useEffect(() => {
        const query=userQuery(userInfo?._id)
        client.fetch(query).then((data)=>{
            setUser(data[0])
        })
    }, [])

return (
    <div className='bg-black text-white'>
        <div className='hidden md:flex flex-row divide-x divide-gray-900 h-screen'>
        {/* Sidebar component*/}
        <div className='px-2 py-4 bg-gray-950'>
            <Sidebar user={user && user} />
        </div>
        <div className='px-4 pt-4 flex-auto w-3/5 flex-col divide-y divide-gray-900 '>
            {/**right greetings */}
            <div className='flex pb-2 justify-between'>
                <div className='flex flex-col'>
                    <span className='font1 text-3xl'>Hola {user?.userName},</span>
                    <span className='font2 text-xl'>Namste..</span>
                </div>
                {/** left stamp and userprof */}
                <div className='flex flex-row '>    
                        {user && lon=='yes' &&
                        <Link to={`/userprofile/${user?.slug}`}>
                        <div className='pt-3 px-5'>
                            {user && user?.imgl.length > 1 &&
                            <Avatar src={user?.imgl}/>
                            }
                            {user && user?.imgl.length == 1 &&
                            <Avatar sx={{bgcolor: deepOrange[400]}}>{user?.imgl}</Avatar>
                            }
                        </div>
                        </Link>
                        }
                        {(lon=='no' || lon==null) &&
                        <div className='pt-3'>
                            <Link className='bg-cyan-900 text-sky-300 rounded-lg px-2 py-2 hover:bg-sky-600 hover:text-cyan-400' to='/login'>Login</Link>
                        </div>
                    }
                    {user==undefined && lon=='yes' &&
                    <div className='h-[24px] w-[24px]'>
                        <Spinner/>
                    </div>
                    }
                    <img src={stamp} className='h-[90px] w-[90px] ' />
                
                    </div>
            </div>
            <div>
            
            </div>
        </div>
        </div>
        {/* For mobile screen  */}
        <div className='flex md:hidden h-screen flex-col'>
            <div className='px-2 pt-2 w-full flex flex-row justify-between'>
            <div className='flex pb-2 justify-between'>
            <div className='flex flex-col'>
                    <span className='font1 text-2xl'>Hola {user?.userName},</span>
                    <span className='font2 text-md'>Namste..</span>
                </div>
                </div>
            </div>
        </div>
    </div>
)
}

export default EHome
