import React, { useState } from 'react'
import moon from '../Assets/moon.jpg'
import { Link, NavLink, useNavigate } from 'react-router-dom'
import img from '../Assets/sound-wave.png'
import VisibilityIcon from '@mui/icons-material/Visibility';
import { client } from '../client';
import { login } from '../data';

const Logined = () => {
const nav = useNavigate()
const [pass, setPass] = useState('')
const [user, setUser] = useState()
const [cowp, setCowp] = useState()
const emailChange=(evnt)=>{
    const query = login(evnt.target.value)
    localStorage.setItem('lon','no')
    client.fetch(query).then((data)=>{
        setUser(data[0])
    })
}
const passChange=(evnt)=>{
    setPass(evnt.target.value)
}
const [togglePass, setTogglePass] = useState('password')
const onToggle=()=>{
    if (togglePass === 'password'){
        setTogglePass('text')
    }
    else{
        setTogglePass('password')
    }
}
const btnclick=()=>{
    if (user!== undefined && pass === user['password']){
        localStorage.setItem('lon','yes')
        localStorage.setItem('user',JSON.stringify(user))
        nav('/',{replace:true})
    }
    else{
        setCowp(true)
    }
}
return (
    <div style={{backgroundImage:`linear-gradient(rgba(0,0,0,0.2),rgba(0,0,0,0.6)),url(${moon})` ,backgroundRepeat:'no-repeat',backgroundPosition:'center',backgroundSize:'cover'}} className='h-screen text-white'>
        <header className="text-gray-400 body-font">
        <div className="container mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center">
    <nav className="flex lg:w-2/5 flex-wrap items-center text-base md:ml-auto">
    <NavLink className="mr-5 hover:text-white" to='/sign'>Sign In</NavLink>
    <NavLink className="mr-5 hover:text-white" to='/info'>Info</NavLink>
    </nav>
    <NavLink className="flex order-first lg:order-none lg:w-1/5 title-font font-medium items-center text-white lg:items-center lg:justify-center mb-4 md:mb-0">
    <img src={img} alt="logo"  height='80px' width='80px'/>
    <span className="ml-3 text-xl xl:block font-extrabold lg:hidden text-cyan-400" style={{fontSize:'35px'}}>Echo</span>
    </NavLink>
    <div className="lg:w-2/5 inline-flex lg:justify-end ml-5 lg:ml-0">
    </div>
  </div>
</header>
<br />
<br />
<div className="flex  justify-center ">
<div className='border-2 border-white flex flex-col w-[425px] h-[380px] flex justify-center items-center rounded-lg '>
<h1 style={{fontSize:'32px'}}>Login</h1>
<br />
<input type="email" placeholder='Email ID' onChange={emailChange} className='px-2 py-2 bg-inherit rounded-md border-2 border-white w-1/2' />
<br />
<input type={togglePass}  onChange={passChange} id="pass" className='px-2 py-2 bg-inherit rounded-md border-2 border-white w-1/2' placeholder='Password' />
<button onClick={onToggle} className='hover:text-pink-600'><VisibilityIcon/></button>
<Link to='/fp' className='hover:text-blue-600'>Forgot Password?</Link>
{cowp === true &&
<div>
    <h2 className='font-extrabold'>Wrong username or password</h2>
</div>
}
<br />
<button onClick={btnclick} className='text-cyan-600 bg-sky-900 hover:text-sky-400 hover:bg-cyan-900 px-2 py-2 rounded-lg'>Login</button>
</div>
</div>
</div>
)
}

export default Logined
