import React, { useEffect, useState } from 'react'
import { userQuery } from '../data'
import { client } from '../client'
import img from '../Assets/sound-wave.png'
import { NavLink, useNavigate } from 'react-router-dom'
import VisibilityIcon from '@mui/icons-material/Visibility';
const Password = () => {
    const nav = useNavigate()
    const userInfo = localStorage.getItem('user') !== undefined ? JSON.parse(localStorage.getItem('user')) : localStorage.clear()
    const [user, setUser] = useState()
    const [pass, setPass] = useState('')
    const [Rpass, setRPass] = useState('')
    const [togglePass, setTogglePass] = useState('password')
    const lon=localStorage.getItem('lon')
    const handleChange=(evnt)=>{
  setPass(evnt.target.value)
  const query = userQuery(userInfo?._id)
  client.fetch(query).then((data)=>{
  setUser(data[0])
    })
}
const handleRChange=(evnt)=>{
  setRPass(evnt.target.value)
}
const ontoogle =()=>{
  if(togglePass==='password'){
    setTogglePass('text')
  return
  }
  setTogglePass('password')
}
  if (user?.password !== undefined && (user?.password !== undefined ? (user?.password).length : 0) !== 0){
        nav('/login' , {replace:true})
    }
    var log = false
    if(pass.length !== 0 && pass === Rpass){
    log = true
    }
    if (log=== true){
      localStorage.setItem('lon','yes')
    client.patch(`${user?._id}`).set({password:`${pass}`}).commit()
    }
    const btnclick =()=>{
        if(pass.length !== 0 && pass === Rpass && log===true){
          localStorage.setItem('user',JSON.stringify(user))
            nav('/profileimage',{replace:true})
        }
    }

return (
    <div className='text-white'>
    <header className="text-gray-400 body-font">
  <div className="container mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center">
    <nav className="flex lg:w-2/5 flex-wrap items-center text-base md:ml-auto">
      <NavLink className="mr-5 hover:text-white" to='/login'>Login</NavLink>
      <NavLink className="mr-5 hover:text-white" to='/info'>Info</NavLink>
    </nav>
    <NavLink className="flex order-first lg:order-none lg:w-1/5 title-font font-medium items-center text-white lg:items-center lg:justify-center mb-4 md:mb-0">
      <img src={img} alt="logo"  height='80px' width='80px'/>
      <span className="ml-3 text-xl xl:block font-extrabold lg:hidden bg-clip-text text-transparent bg-gradient-to-br from-pink-600 to-blue-800" style={{fontSize:'35px'}}>Echo</span>
    </NavLink>
    <div className="lg:w-2/5 inline-flex lg:justify-end ml-5 lg:ml-0">
    </div>
  </div>
</header>
<br />
<br />
<div className="flex  justify-center ">
    <div className='border-2 border-white flex flex-col w-[425px] h-[380px] flex justify-center items-center '>
    <h1 className='text-pink-600 font-extrabold' style={{fontSize:'22px'}}>Password</h1>
    <br />
    <div className="flex">
    <input type={togglePass} className='bg-gray-900 rounded-lg text-pink-600 px-2 py-2' onChange={handleChange} placeholder='Password'/>
    <button className='px-2 hover:text-blue-600 text-pink-600' onClick={ontoogle}><VisibilityIcon/></button>
    </div>
    <br />
    <div className='flex'>
    <input type={togglePass} className='bg-gray-900 rounded-lg text-blue-600 px-2 py-2' onChange={handleRChange} placeholder='Re-enter Password'/>
    <button className='px-2 hover:text-pink-600 text-blue-600' onClick={ontoogle}><VisibilityIcon/></button>
    </div>
    <br />
    {(lon == 'no' || lon==null) && user !== undefined &&
    <div className='text-center'>
    <h1 className='text-blue-600 font-extrabold' style={{fontSize:'15px'}}>{user.email}</h1>
    <h1 className='text-pink-600 font-extrabold' style={{fontSize:'15px'}}>{user.slug}</h1>
    </div>
    }
    <br />
    <button onClick={btnclick} className='px-4 py-1 rounded-lg bg-cyan-600 hover:bg-sky-400 hover:text-cyan-900 '>Set</button>
    </div>
    </div>
    </div>
)
}

export default Password
