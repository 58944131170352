import React from 'react'

const Player = () => {
return (
    <div className='flex flex-row px-4 py-2 '>
        <div className=''>
            <img className='w-[60px] h-[60px] rounded-full' src="https://images.unsplash.com/photo-1470813740244-df37b8c1edcb?crop=entropy&cs=tinysrgb&fit=crop&fm=jpg&h=700&ixid=MnwxfDB8MXxyYW5kb218MHx8c3BhY2V8fHx8fHwxNjkyMDM4OTk1&ixlib=rb-4.0.3&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=900" alt="none" />
        </div>
    </div>
)
}

export default Player
