import React, { useState } from 'react'
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Spinner from '../Container/Spinner';
import { client } from '../client';
const Imgcard = () => {
    const [image, setImage] = useState()
    const [loadimg, setLoadimg] = useState(false)
    const [wrongimg, setWrongimg] = useState(false)
    const imgupload =(e)=>{
        const img = e.target.files[0]
    if(img.type === 'image/jpeg' || img.type === 'image/jpg' || img.type === 'image/png' || img.type === 'image/ico'){
            setLoadimg(true)
            setWrongimg(false)
            setImage(true)
            client.assets.upload('image',img,{contentType:img.type,filename:img.name})
            .then((document)=>{
                setImage(document)
                setLoadimg(false)
            })
            .catch((error)=>{
                console.log('Upload error ',error);
            })
    }
    else{
    setLoadimg(false)
    setWrongimg(true)
    }
    }
return (
    <div className='flex flex-col justify-center items-center text-center'>
        <span className='text-2xl font-extrabold text-gray-400'>Image Card</span>
        <br />
        <span className='px-4 text-slate-500'>Upload an image and add music to background of the image</span>
        <br />
        <div className='w-2/3 md:w-1/3 bg-gray-900 text-white rounded-lg h-320  '>
                    {loadimg &&
                    <div className='flex flex-row justify-center'>
                        <Spinner/>
                    </div>
                    }
                    {wrongimg &&
                    <p>Wrong image type</p>
                    }
                    {!image ?(
                    <label>
                    <CloudUploadIcon className='text-red-400' />
                    <br />
                    <h1>Upload cover image here</h1>
                    <span className='text-gray-400 px-3' style={{fontSize:'10px'}}>Recommended: Upload png, jpeg, jpg ,etc of high  quality</span>
                    <input type="file" name='music' className='w-0 h-0' onChange={imgupload}/>
                    </label>):(
                        <div className='relative h-full'>
                            <img src={image.url} alt="img upload" className='h-full w-full rounded-lg' />
                        </div>
                    )}
                </div>
    </div>
)
}

export default Imgcard