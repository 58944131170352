import React, { useEffect, useState } from 'react'
import {Link, NavLink, useNavigate} from 'react-router-dom'
import moon from '../Assets/moon.jpg'
import img from '../Assets/sound-wave.png'
import {client} from '../client'
import { userQuery } from '../data'
import { emailcheck } from '../data'
const Login = () => {
    const [user, setUser] = useState()
    const nav = useNavigate()
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [slug, setSlug] = useState('')
    const [slugset, setSlugset] = useState()
    const [emailset, setEmailset] = useState()
    const firstl=name.split('')[0]
    const slugchange=(e)=>{
      setSlug(e.target.value)
      const query = userQuery(e.target.value)
      client.fetch(query).then((data)=>{setSlugset(data)})
    }
    const emailchange=(e)=>{
      setEmail(e.target.value)
      const query = emailcheck(e.target.value)
      client.fetch(query).then((data)=>{setEmailset(data)})
    }
    const createUser=()=>{
    if (name && email && slug && Object.keys(slugset).length === 0 && Object.keys(emailset).length === 0 && firstl ){
      const doc ={
        _type:'user',
        _id:slug,
        slug:slug,
        userName:name,
        imgl:firstl,
        password:'',
        email:email,
      }
      console.log(doc , user ,client.createIfNotExists(doc));
      client.createIfNotExists(doc)
      localStorage.setItem('user',JSON.stringify(doc))
      localStorage.setItem('lon','no')
      nav('/password',{replace: true})
    }}
return (
    <div style={{backgroundImage:`linear-gradient(rgba(0,0,0,0.2),rgba(0,0,0,0.6)),url(${moon})` ,backgroundRepeat:'no-repeat',backgroundPosition:'center',backgroundSize:'cover'}} className='h-screen text-white'>
    <header className="text-gray-400 body-font">
  <div className="container mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center">
    <nav className="flex lg:w-2/5 flex-wrap items-center text-base md:ml-auto">
      <NavLink className="mr-5 hover:text-white" to='/login'>Login</NavLink>
      <NavLink className="mr-5 hover:text-white" to='/info'>Info</NavLink>
    </nav>
    <NavLink className="flex order-first lg:order-none lg:w-1/5 title-font font-medium items-center text-white lg:items-center lg:justify-center mb-4 md:mb-0">
      <img src={img} alt="logo"  height='80px' width='80px'/>
      <span className="ml-3 text-xl xl:block font-extrabold lg:hidden text-cyan-400" style={{fontSize:'35px'}}>Echo</span>
    </NavLink>
    <div className="lg:w-2/5 inline-flex lg:justify-end ml-5 lg:ml-0">
    </div>
  </div>
</header>
    <br />
    <br />
    <div className="flex  justify-center ">
<div className='border-2 border-white rounded-lg flex flex-col w-[425px] h-[380px] flex justify-center items-center text-center '>
<h1 style={{fontSize:'32px'}}>Sign up</h1>
<br />
  <div className='w-full px-5'>
      <input type="text" className='w-full bg-transparent border-2 border-white rounded-lg px-0.5 py-1' onChange={(e)=>{setName(e.target.value)}} placeholder='Your Name'/>
  </div>
  <br />
  <div className='w-full px-5'>
      <input type="text" className='w-full bg-transparent border-2 border-white rounded-lg px-2 py-1' onChange={emailchange} placeholder='Email ID'/>
  </div>
  {emailset && Object.keys(emailset).length !== 0 &&
    <h1 className='text-red-600'>User email already used. Try other email</h1>
  }
  <br />
  <div className='flex flex-row justify-between'>
    <div className='px-4 pt-1'>
      <span >Username:</span>
    </div>
    <div >
      <input  type="text" placeholder='Username' onChange={slugchange} className=' bg-transparent border-2 border-white rounded-lg px-2 py-1'/>
    </div>
  </div>
  {slugset && Object.keys(slugset).length !== 0 &&
    <h1 className='text-red-600'>User name already used. Try something else</h1>
  }
  <br />
  <div>
    <button className='px-2 py-2 bg-red-600 hover:bg-gray-600 hover:text-red-100 rounded-lg' onClick={createUser}>Sign Up</button>
  </div>
</div>
</div>
</div>
)

}

export default Login
