import React, { useEffect, useState } from 'react'
import {Link} from 'react-router-dom'
import { cyan } from '@mui/material/colors'
import '../App.css'
import { post } from '../data'
import { client } from '../client'
import Avatar from '@mui/material/Avatar';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import Spinner from './Spinner';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import Popup from '../Micro_Component/Popup'
const Body = () => {
  const lon=localStorage.getItem('lon')
  const [Pins, setPins] = useState()
  const [open, setOpen] = useState(false)
    useEffect(() => {
      setTimeout(()=>{
          {lon=='no' || lon==null &&
            setOpen(true)
          }
      },6000)
    }, [])
  const chart={'body':
    [
    {
      name:'Global hit',
      img_src:'https://images.unsplash.com/photo-1530295314625-30d3b777ac7a?crop=entropy&cs=tinysrgb&fit=crop&fm=jpg&h=150&ixid=MnwxfDB8MXxyYW5kb218MHx8bW91bnRhaW5zfHx8fHx8MTY5MTU5NzgxOQ&ixlib=rb-4.0.3&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=150'
      ,link:'global'
    },
    {
      name:"India's hits",
      img_src:'https://images.unsplash.com/photo-1500534314209-a25ddb2bd429?crop=entropy&cs=tinysrgb&fit=crop&fm=jpg&h=150&ixid=MnwxfDB8MXxyYW5kb218MHx8bW91bnRhaW5zfHx8fHx8MTY5MTUxMjQzMg&ixlib=rb-4.0.3&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=150'
      ,link:'in'
    },
    {
      name:'Hip Hop',
      img_src:'https://images.unsplash.com/photo-1445262102387-5fbb30a5e59d?crop=entropy&cs=tinysrgb&fit=crop&fm=jpg&h=150&ixid=MnwxfDB8MXxyYW5kb218MHx8bmF0dXJlfHx8fHx8MTY5MTU5NzI0OA&ixlib=rb-4.0.3&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=150'
      ,link:'hip-hop'
    },
    {
      name:'Global hit',
      img_src:'https://images.unsplash.com/photo-1500534314209-a25ddb2bd429?crop=entropy&cs=tinysrgb&fit=crop&fm=jpg&h=150&ixid=MnwxfDB8MXxyYW5kb218MHx8bW91bnRhaW5zfHx8fHx8MTY5MTUxMjQzMg&ixlib=rb-4.0.3&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=150'
    ,link:'classical'
    },
    {
      name:'Global hit',
      img_src:'https://images.unsplash.com/photo-1500534314209-a25ddb2bd429?crop=entropy&cs=tinysrgb&fit=crop&fm=jpg&h=150&ixid=MnwxfDB8MXxyYW5kb218MHx8bW91bnRhaW5zfHx8fHx8MTY5MTUxMjQzMg&ixlib=rb-4.0.3&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=150'
    ,link:'classical'
    },
  ]
  }  
  useEffect(() => {
    const q=post()
    client.fetch(q).then((data)=>{
      setPins(data)
    })
  }, [])
  const msg="Swipe >"
  return (
    <div className='text-center text-cyan-600'>
      <Popup trigger={open} className='text-cyan-600 text-center flex md:hidden' setTrigger={setOpen}>
      <h1 className=' font-extrabold'>Login or Sign Up</h1>
          <br />
      <div className='flex justify-center gap-4'>
        <Link to='/login' className='bg-sky-900 px-2 py-2 rounded-lg'>Login</Link>
        <Link to='/sign' className='bg-sky-900 px-2 py-2 rounded-lg '>Sign Up</Link>
      </div>
      </Popup>
    
      <div className='px-1 pt-3 md:px-4 relative'>
      <img className='rounded-lg h-[130px] md:h-[180px] w-full object-cover ' src="https://images.unsplash.com/photo-1502481851512-e9e2529bfbf9?crop=entropy&cs=tinysrgb&fit=crop&fm=jpg&h=900&ixid=MnwxfDB8MXxyYW5kb218MHx8bmF0dXJlLG1vcm5pbmcgc2t5fHx8fHx8MTY5MTQzMTgxMQ&ixlib=rb-4.0.3&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1600" alt="" />
      
      <div class="absolute bottom-0 px-4 text-center py-10 bg-transparent w-full">
      <h1 class="text-gray-950 text-opacity-50 font-bold text-6xl md:text-8xl">Echo music </h1>
    </div>
    </div>
    <div className='hidden md:flex text-cyan-300 text-center items-center'>
        <Popup trigger={open} setTrigger={setOpen}>
          <h1 className=' font-extrabold'>Login or Sign Up</h1>
          <br />
      <div className='flex justify-center gap-4'>
        <Link to='/login' className='bg-sky-900 px-2 py-2 rounded-lg'>Login</Link>
        <Link to='/sign' className='bg-sky-900 px-2 py-2 rounded-lg '>Sign Up</Link>
      </div>
      
        </Popup>
      </div>
      <br />
    
    <div className='px-6 md:px-10'>
    <br /> 
    <span className=' text-gray-400 text-xl font-extrabold'>Pins</span>
    <br />
    <span className='text-xs text-gray-400'>Press snowflake for more details</span>
    <br />
    {Pins == undefined &&
      <div className='text-center items-center flex flex-col justify-center'>
      <Spinner/>
  </div>
    } 
    <br />
    <div className="flex flex-row gap-8 py-2 overflow-x-auto rounded-lg">
      {Pins?.map((pin)=>(
        <div className='bg-black rounded-lg px-10 '>
          <Link to={`/userprofile/${pin?.postedBy.slug}`} className='flex pt-2 px-3 flex-row '>
            <div className='flex flex-col divide-gray-900'>
            <div className='hover:text-lime-400 divide-y'>
            {pin?.postedBy.imgl.length > 1 &&
            <Avatar src={pin?.postedBy.imgl}/>
            }
            {pin?.postedBy.imgl.length == 1 &&
            <Avatar sx={{bgcolor: cyan[400]}}>{pin?.postedBy.imgl}</Avatar>
            }
            </div>
            </div>
            <div className='px-2.5 py-1.5'>
              <span className='text-cyan-400 hover:text-lime-400  '>{pin?.postedBy.slug}</span>
            </div>
          </Link>
          <div className='p-1'>
            <span className='text-gray-400 text-xs'>{pin.text}</span>
          </div>
          <div className='text-center'>
          <span className='text-xs'>Posted by <b className='text-sky-400'>{pin?.postedBy.userName}</b></span>
          </div>
          <div className='pb-2 pt-1 text-center hover:text-cyan-600'>
          <Link to={`/epin/${pin._id}`}><AcUnitIcon/></Link></div>
        </div>
      ))
      }
    </div>

    </div>
    </div>
  )
}

export default Body