import React from 'react'
import img from '../Assets/sound-wave.png'
import { NavLink } from 'react-router-dom'

const FP = () => {
return (
    <div className='text-white'>
        <header className="text-gray-400 body-font">
  <div className="container mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center">
    <nav className="flex lg:w-2/5 flex-wrap items-center text-base md:ml-auto">
      <NavLink className="mr-5 hover:text-white" to='/login'>Login</NavLink>
      <NavLink className="mr-5 hover:text-white" to='/info'>Sign In</NavLink>
    </nav>
    <NavLink className="flex order-first lg:order-none lg:w-1/5 title-font font-medium items-center text-white lg:items-center lg:justify-center mb-4 md:mb-0">
      <img src={img} alt="logo"  height='80px' width='80px'/>
      <span className="ml-3 text-xl xl:block font-extrabold lg:hidden bg-clip-text text-transparent bg-gradient-to-br from-pink-600 to-blue-800" style={{fontSize:'35px'}}>Echo</span>
    </NavLink>
    <div className="lg:w-2/5 inline-flex lg:justify-end ml-5 lg:ml-0">
    </div>
  </div>
</header>
<br />
<br />
<div className="flex  justify-center ">
    <div className='border-2 border-white flex flex-col w-[425px] h-[380px] flex justify-center items-center '>
<h1 style={{fontSize:'22px'}} className='font-extrabold'>Forgot Password</h1>
</div>
</div>
    </div>
)
}

export default FP
