import '../App.css'
import React from 'react'
import { Link } from 'react-router-dom'
import img from '../Assets/sound-wave.png'
import { Avatar } from '@mui/material'
import Spinner from '../Container/Spinner'
import HomeIcon from '@mui/icons-material/Home';
import { deepOrange } from '@mui/material/colors'
import EmailIcon from '@mui/icons-material/Email';
const eSidebar = ({user}) => {
    const lon =localStorage.getItem('lon')
    const name_var =['नाम','Name','नाम्','ಹೆಸರು','नाम','പേര്','नाम','નામ','ਨਾਮ','नाम','பெயர்']
    const i = Math.floor(Math.random() * 10)
return (
    <div className='text-white flex flex-col gap-2 divide-y-4 divide-gray-900'>
        <Link to='/'>
            <div className='p-3 flex'>
                <img src={img} alt="logo" height='70px' width='70px' />
                <div className='flex flex-col pt-3'>
                <div>
                <span className={` px-4 font4  font-extrabold bg-clip-text text-transparent bg-gradient-to-r from-sky-400 to-cyan-400`} style={{fontSize:'30px'}}>Echo</span>
                </div>
                </div>
            </div>
        </Link>
        <div  className='p-1  flex flex-col pt-2 '>
            {/* Letter info*/}
            <div className='flex flex-row justify-between'>
            <span className='pt-2 font3 text-md line-clamp-3 text-gray-400'>Letter Info</span>
            <div className='px-2'>
            {user && lon=='yes' &&
                <Link className='hover:animate-pulse' to={`/userprofile/${user?.slug}`}>
                <div className='flex flex-col'>
                {user && user?.imgl.length > 1 &&
                    <Avatar src={user?.imgl}/>
                }
                {user && user?.imgl.length == 1 &&
                    <Avatar sx={{bgcolor: deepOrange[400]}}>{user?.imgl}</Avatar>
                }
                </div>
                </Link>
                }
                {user==undefined && lon=='yes' &&
                    <div className=' flex flex-row justify-center'>
                        <Spinner/>
                    </div>
                }
                {(lon=='no' || lon==null) &&
                    <div className='flex justify-between'>
                        <div className='px-4 py-2'>
                            <Link className='bg-cyan-900 text-sky-300 rounded-lg px-2 py-2 hover:bg-sky-600 hover:text-cyan-400' to='/sign'>Sign In</Link>
                        </div>
                        <div className='px-2  py-2'>
                            <Link className='bg-cyan-900 text-sky-300 rounded-lg px-2 py-2 hover:bg-sky-600 hover:text-cyan-400' to='/login'>Login</Link>
                        </div>
                    </div>
                }
            </div>
            </div>
            {/** user info */}
            <div className='flex flex-col gap-2 divide-y divide-gray-900'>
            <div>
            <div className='pt-2 flex gap-3 text-gray-400'>
                <div>
                <span>{name_var[i]} :</span>
                </div>
                <div>
                    {user && lon=='yes' &&
                    <Link to={`/userprofile/${user?.slug}`} className='text-sky-400 hover:text-white underline underline-offset-4 hover:underline-offset-8'>{user?.slug}</Link>
                    }
                    {user==undefined &&
                    <span>___________</span>
                    }
                </div>
            </div>
            <div className='pt-2 flex gap-3 text-gray-400'>
                <div>
                    <span>Address:</span>
                </div>
                <span>Music to 💓</span>
            </div>
            </div>
            
            </div>
        </div>
    </div>
)
}

export default eSidebar
