import React, { useEffect, useState } from 'react'
import SideBar from '../Container/Sidebar'
import { client } from '../client'
import { userQuery } from '../data'
import { Link } from 'react-router-dom'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import CloseIcon from '@mui/icons-material/Close';
import Spinner from '../Container/Spinner'
import { Route,Routes } from 'react-router-dom'
import Post from '../Container/Post'
import Avatar from '@mui/material/Avatar';
import Body from '../Container/Body'
import Music_post from '../Micro_Component/Music_post'
import Lyrics from '../Micro_Component/Lyrics'
import Epin from '../Micro_Component/Epin'
import { deepOrange } from '@mui/material/colors'
import Imgcard from '../Micro_Component/Imgcard'
import Upload from '../Micro_Component/Upload'
import UserPf from '../Container/UserPf'
import LyricsPg from '../Container/LyricsPg'
import Chart from '../Container/Chart'
import '../App.css'
import Player from '../player/Player'
const Home_echo = () => {
    const userInfo = localStorage.getItem('user') !== "undefined" ? JSON.parse(localStorage.getItem('user')) : localStorage.clear()
    const lon=localStorage.getItem('lon')
    const [user, setUser] = useState()
    
    useEffect(() => {
        const query=userQuery(userInfo?._id)
        client.fetch(query).then((data)=>{
            setUser(data[0])
        })
    }, [])
    const mood=(n)=>{
        if(n==1){
            return 'bg-gradient-to-t from-neutral-950 to-95% via-slate-950 via-60% to-rose-950 md:via-20% md:to-100% text-white'
        }
        else if(n==2){
            return 'bg-gradient-to-t from-neutral-950 to-95% via-slate-950 via-40% to-sky-950 md:via-20% md:to-100% text-white'
        }
        else if(n==3){
            return 'bg-gradient-to-t from-black to-95% via-slate-950 via-40% to-black-950 md:via-20% md:to-100% text-white'
        }
        else if(n==4){
            return 'bg-gradient-to-t from-neutral-950 to-95% via-slate-950 via-60% to-pink-950 md:via-20% md:to-100% text-white'
        }
        else {
            return 'bg-gradient-to-t from-neutral-950 to-95% via-slate-950 via-60% to-zinc-700 md:via-20% md:to-100% text-white'
        }
    }
    const moodsb=(n)=>{
        if(n==1){
            return 'fixed w-4/5 h-screen overflow-y-auto shadow-md z-10 animate-slide-in bg-gradient-to-tr from-neutral-950 to-95% via-slate-950 via-60% to-rose-950 md:via-20% md:to-100% text-white'
        }
        else if(n==2){
            return 'fixed w-4/5 h-screen overflow-y-auto shadow-md z-10 animate-slide-in bg-gradient-to-tr from-neutral-950 to-95% via-slate-950 via-60% to-sky-950 md:via-20% md:to-100% text-white'
        }
        else if(n==3){
            return 'fixed w-4/5 h-screen overflow-y-auto shadow-md z-10 animate-slide-in bg-gradient-to-tr from-neutral-950 to-95% via-slate-950 via-60% to-cyan-950 md:via-20% md:to-100% text-white'
        }
        else if(n==4){
            return 'fixed w-4/5 h-screen overflow-y-auto shadow-md z-10 animate-slide-in bg-gradient-to-tr from-neutral-950 to-95% via-slate-950 via-60% to-pink-950 md:via-20% md:to-100% text-white'
        }
        else {
            return 'fixed w-4/5 h-screen overflow-y-auto shadow-md z-10 animate-slide-in bg-gradient-to-tr from-neutral-950 to-95% via-slate-950 via-60% to-zinc-700 md:via-20% md:to-100% text-white'
        }
    }
    const i =3

    const [toggleSidebar, setToggleSidebar] = useState(false);
    const [hover, setHover] = useState('music')
    let sm,sp,sc=''
    if(hover=='music'){
        sm='bg-clip-text text-transparent bg-gradient-to-t from-white to-slate-600 text-3xl'
        sp='hover:bg-clip-text hover:text-transparent hover:bg-gradient-to-t hover:from-zinc-900 hover:to-white text-gray-400 hover:text-3xl text-2xl '
        sc='hover:bg-clip-text hover:text-transparent hover:bg-gradient-to-t hover:from-zinc-900 hover:to-white text-gray-400 hover:text-3xl text-2xl '
    }
    else if(hover=='post'){
        sp='bg-clip-text text-transparent bg-gradient-to-t from-white to-slate-600 text-3xl'
        sm='hover:bg-clip-text hover:text-transparent hover:bg-gradient-to-t hover:from-zinc-900 hover:to-white text-gray-400 hover:text-3xl text-2xl '
        sc='hover:bg-clip-text hover:text-transparent hover:bg-gradient-to-t hover:from-zinc-900 hover:to-white text-gray-400 hover:text-3xl text-2xl '
    }
    else if(hover=='cine'){
        sc='bg-clip-text text-transparent bg-gradient-to-t from-white to-slate-600 text-3xl'
        sp='hover:bg-clip-text hover:text-transparent hover:bg-gradient-to-t hover:from-zinc-900 hover:to-white text-gray-400 hover:text-3xl text-2xl '
        sm='hover:bg-clip-text hover:text-transparent hover:bg-gradient-to-t hover:from-zinc-900 hover:to-white text-gray-400 hover:text-3xl text-2xl '
    }

return (
    <div className='bg-black text-white'>
        <div className="hidden md:flex h-screen">
            <div className='h-screen px-7 py-4'>
            <SideBar user={user && user} />
            </div>
            <div className='flex-auto w-3/5 flex-col p-2'>
                <div className='flex flex-row justify-between p-2'>
                    <div className='pt-1'>
                        <span className='font-extrabold ' style={{fontSize:'24px'}}><b className='text-cyan-400'>Namste,</b></span>
                        {lon=='yes' &&
                        <h1 className='font-extrabold text-gray-400' style={{fontSize:'20px'}}>{user?.userName}</h1>
                        }
                    </div>
                    <div>
                        {user && lon=='yes' &&
                        <Link to={`/userprofile/${user?.slug}`}>
                        <div className='pt-3 px-5'>
                            {user && user?.imgl.length > 1 &&
                            <Avatar src={user?.imgl}/>
                            }
                            {user && user?.imgl.length == 1 &&
                            <Avatar sx={{bgcolor: deepOrange[400]}}>{user?.imgl}</Avatar>
                            }
                        </div>
                        </Link>
                        }
                        {(lon=='no' || lon==null) &&
                        <div className='pt-3'>
                            <Link className='bg-cyan-900 text-sky-300 rounded-lg px-2 py-2 hover:bg-sky-600 hover:text-cyan-400' to='/login'>Login</Link>
                        </div>
                    }
                    {user==undefined && lon=='yes' &&
                    <div className='h-[24px] w-[24px]'>
                        <Spinner/>
                    </div>
                    }
                    </div>
                </div>
                <div className='p-2'>
                    <div className='px-36 flex justify-between flex-row'>
                        <Link className={`${sp}`} onClick={()=>{setHover('post')}} to='/post'>Post</Link>
                        <Link className={`${sm}`} onClick={()=>{setHover('music')}} to='/'>Music</Link>
                        <Link className={`${sc}`} onClick={()=>{setHover('cine')}} to='/uploadmetadata'>Cine</Link>
                    </div>
                </div>
                <div className='overflow-y-auto h-[75vh]'>
                    <Routes>
                        <Route path='/post' element={<Post user={user && user}/>}/>
                        <Route path='/*' element={<Body user={user && user}/>}/>
                        <Route path='/epin/:id' element={<Epin />}/>
                        <Route path='/userprofile/:id' element={<UserPf />}/>
                        <Route path='/chart/:n' element={<Chart />}/>
                        <Route path='/mpost' element={<Music_post user={user && user}/>}/>
                        <Route path='/lyrics' element={<Lyrics user={user && user}/>}/>
                        <Route path='/lyric/:sn' element={<LyricsPg/>}/>
                        <Route path='/imgcard' element={<Imgcard user={user && user}/>}/>
                        <Route path='/uploadmetadata' element={<Upload/>}/>
                    </Routes>
                </div>
                
            </div>
            {user &&
            <div className='player'>
                <div className='w=full rounded-lg bg-cyan-900'>
                    <Player/>
                </div>
                </div>
            }
        </div>
        
        <div className='flex flex-col md:hidden h-screen'>
            <div className='flex flex-row justify-between'>
                <div className='p-2 pt-4'>
                    <span className='font-extrabold ' style={{fontSize:'24px'}}><b className='text-cyan-600'>Namste,</b></span>
                    {lon=='yes'&&
                    <h1 className='font-extrabold text-gray-400' style={{fontSize:'20px'}}>{user?.userName}</h1>
                    }
                </div>
                <div className='p-5'>
                    {user && lon=='yes'&&
                    <Link to={`/userprofile/${user?.slug}`}>
                    <div >
                    {user && user?.imgl.length > 1 &&
                            <Avatar src={user?.imgl}/>
                            }
                            {user && user?.imgl.length == 1 &&
                            <Avatar sx={{bgcolor: deepOrange[400]}}>{user?.imgl}</Avatar>
                            }</div>
                    </Link>
                    }
                    {(lon=='no' || lon==null) &&
                        <div className='pt-3'>
                            <Link className='bg-cyan-900 text-sky-300 rounded-lg px-2 py-2 hover:bg-sky-600 hover:text-cyan-400' to='/login'>Login</Link>
                        </div>
                    }
                    {user==undefined && lon=='yes' &&
                    <div className='h-[24px] w-[24px]'>
                        <Spinner/>
                    </div>
                    }
                </div>
            </div>
            <div className='px-1'>
                <button className='hover:text-gray-700' onClick={() => setToggleSidebar(true)} ><MoreHorizIcon fontSize='large'/></button>
            </div>
            {toggleSidebar && (
            <div className={moodsb(i)}>
            <div className="absolute w-full flex justify-end items-center p-2">
            <button className="cursor-pointer" onClick={() => setToggleSidebar(false)}><CloseIcon fontSize='large'/></button>
            </div>
            <div className='px-7'>
            <SideBar closeToggle={setToggleSidebar} user={user && user}  />
            </div>
        </div>
        )}
        <div className='p-2'>
                    <div className='px-10 flex justify-between flex-row'>
                        <Link className={`${sp}`} onClick={()=>{setHover('post')}} to='/post'>Post</Link>
                        <Link className={`${sm}`} onClick={()=>{setHover('music')}} to='/'>Music</Link>
                        <Link className={`${sc}`} onClick={()=>{setHover('cine')}} to='/uploadmetadata'>Cine</Link>
                    </div>
                </div>
                <div className='w-full overflow-y-scroll'>
                <Routes>
                <Route path='/post' element={<Post user={user && user}/>}/>
                <Route path='/*' element={<Body user={user && user}/>}/>
                <Route path='/epin/:id' element={<Epin />}/>
                <Route path='/mpost' element={<Music_post user={user && user}/>}/>
                <Route path='/lyrics' element={<Lyrics user={user && user}/>}/>
                <Route path='/userprofile/:id' element={<UserPf />}/>
                <Route path='/lyric/:sn' element={<LyricsPg/>}/>
                <Route path='/chart/:n' element={<Chart />}/>
                <Route path='/imgcard' element={<Imgcard user={user && user}/>}/>
                <Route path='/uploadmetadata' element={<Upload/>}/>
                </Routes>
                </div>
        </div>
        {user &&
        <div className='player'>
                <div className='w=full rounded-lg bg-gray-900 '>
                    <Player/>
                </div>
                </div>
        }
        </div>
)
}

export default Home_echo